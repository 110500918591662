import { html } from "lit-html";
import { renderCssClasses } from "Helper/renderHelper/renderCssClasses";

type RenderIconArgs = {
  iconId: string;
  cssClasses?: string;
};

export const renderIcon = (args: RenderIconArgs) => {
  return html`
    <zho-e-icon class="zho-e-icon ${renderCssClasses(args.cssClasses)}" icon-id="${args.iconId}"></zho-e-icon>
  `;
};
