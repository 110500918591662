// Prefixes
export const STATE_PREFIX = "zho-state-";

// Helper
export const TOUCH = "zho-h-touch";
export const NO_TOUCH = "zho-h-no-touch";
export const INVERTED = "zho-h-inverted";

// States
export const ACTIVE = "zho-state-active";
export const LOADING = "zho-state-loading";
export const OPEN = "zho-state-open";
export const LOADED = "zho-state-loaded";
export const ERROR = "zho-state-error";
export const HIDDEN = "zho-state-hidden";
export const VISIBLE = "zho-state-visible";
export const INVISIBLE = "zho-state-invisible";
export const INACTIVE = "zho-state-inactive";
export const UNSTARTED = "zho-state-unstarted";
export const ANIMATE = "zho-state-animate";
export const ANIMATING = "zho-state-animating";
export const IN_VIEWPORT = "zho-state-invp";
export const DRAGGING = "zho-state-dragging";
export const SCROLLING = "zho-state-scrolling";
export const MINIMAL = "zho-state-minimal";
export const UP = "zho-state-up";
export const DOWN = "zho-state-down";
export const DISABLED = "zho-state-disabled";
export const SELECTED = "zho-state-selected";
export const CAN_SUBMIT = "zho-state-can-submit";
export const HAS_OVERFLOW = "zho-state-has-overflow";
export const HASCOOKIE = "zho-state-has-cookie";
export const INITIALIZED = "zho-state-initialized";
export const OVERLAY_OPEN = "zho-state-overlay-open";
export const SUCCESS = "zho-state-success";
export const PLAYING = "zho-state-playing";
export const FINISHED = "zho-state-finished";
export const FILTERED = "zho-state-filtered";
export const EVEN = "zho-state-even";
export const STICKY = "zho-state-sticky";
export const KEYBOARD_FOCUS = "zho-h-keyboard-focus";
export const FOCUS = "zho-state-focus";
export const CAN_SCROLL_RIGHT = "zho-state-can-scroll-right";
export const CAN_SCROLL_LEFT = "zho-state-can-scroll-left";
